import autocomplete from "@/components/autocomplete/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "delivery-zasilkovna",
    data() {
        return {
            countryList: [],
            filterCountryList: [],
            cityList: [],
            filterCityList: [],
            selectedCity: null,
            selectedCityRef: null,
            selectedCountry: null,
            selectedCountryRef: null,
            selectedBranch: null,
            isTownsLoading: false,
            isCountryLoading: false,
            isDepartmensLoading: false,
        };
    },
    props: {
        cityError: {
            type: Boolean,
            default: true,
        },
        branchError: {
            type: Boolean,
            default: true,
        },
        countriesList: {
            type: Array,
            default: () => [],
        }
    },
    components: {
        autocomplete,
    },
    computed: {
        ...mapGetters({
            cities: "zasilkovna/cities",
            branches: "zasilkovna/branches",
        }),
    },
    watch: {
    },
    created() {
        this.countryList = this.countriesList;
        this.filterCountryList = this.countriesList;
    },
    methods: {
        ...mapMutations({
            saveAddress: "order/SAVE_DELIVERY_ADDRESS",
        }),

        ...mapActions({
            fetchCity: "zasilkovna/GET_CITY_LIST",
            fetchBranch: "zasilkovna/GET_BRANCH_LIST",
            fetchBranchCountriesList: "zasilkovna/GET_BRANCH_COUNTRIES_LIST",
        }),

        handleCityInput(e) {
            if (e.length < 3) {
                this.filterCityList = this.cityList.slice();
                return;
            }
            this.filterCityList = this.cityList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },

        handleCountryInput(e) {
            console.log("handleCountryInput", e);
            if (e.length < 3) {
                this.filterCountryList = this.countryList.slice();
                return;
            }
            this.filterCountryList = this.countryList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },

        handleBranchInput(e) {
            if (e.length < 1) {
                this.fetchBranch({ city: this.selectedCity });
            }
            this.fetchBranch({ city: this.selectedCity, search: e });
        },

        async handleCitySelect(e) {
            this.selectedCityRef = e.id;
            this.selectedCity = e.name;
            this.isDepartmensLoading = true;
            await this.fetchBranch({ city: this.selectedCity });
            this.isDepartmensLoading = false;
            this.$refs.branchSelector.$el.querySelector("input").value = "";
            this.$emit("select-city", e);
            this.selectedBranch = "";
        },

        async handleCountrySelect(e) {
            this.selectedCountry = e.id;
            this.selectedCountryRef = e.name;
            this.isTownsLoading = true;
            console.log(e);

            this.fetchCity({ country: e.alpha2Code.toLowerCase() }).then((response) => {
                this.cityList = response;
                this.filterCityList = response;
            }).finally(() => {
                this.isTownsLoading = false;
            });
            this.$emit("select-country", e);
            this.selectedBranch = "";
            this.selectedCity = "";
        },
        handleBranchSelect(e) {
            this.$emit("select-branch", e);
        },
    },
};
