// import autocomplete from '@/components/autocomplete/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
// import buyFlowMixin from '@/mixins/buy-flow'

export default {
    name: "delivery-nova-poshta",
    // mixins: [buyFlowMixin],
    data() {
        return {
            town: "",
            townRef: "",
            department: "",
            city: "",
            zipCode: "",
            isValid: false,
            isValidTown: false,
            isValidDepartment: false,
            street: "",
            // cityList: [],
            // filterCityList: [],
            countryList: [],
            filterCountryList: [],

            carrierList: [],
            filterCarrierList: [],
            building: "",
            flat: "",

            selectedCountry: null,
            selectedCountryRef: null,

            selectedCarrier: null,
            selectedCarrierRef: null,
            // selectedCity: null,
            // selectedCityRef: null,
            selectedBranch: null,

            carrierPickupPointList: [],
            filterCarrierPickupPointList: [],
            selectedPickupPointCarrier: null,
            selectedPickupPointCarrierRef: null,

            pickupPointList: [],
            filterPickupPointList: [],
            selectedPickupPoint: null,
            selectedPickupPointRef: null,
        };
    },
    props: {
        countriesList: {
            type: Array,
            default: () => [],
        }
    },
    components: {
        // autocomplete
    },
    computed: {
        ...mapGetters({
            towns: "order/towns",
            departments: "order/departments",
            isTownsLoading: "order/isTownsLoading",
            isAddressLoading: "order/isAddressLoading",
            cities: "zasilkovna/cities",
            branches: "zasilkovna/branches",
        }),
    },
    watch: {
        street() {
            this.saveData();
        },
        selectedCarrier() {
            this.saveData();
        },
        selectedPickupPointCarrier() {
            this.saveData();
        },
        selectedPickupPoint() {
            this.saveData();
        },
        selectedCountry() {
            this.saveData();
        },
        city() {
            this.saveData();
        },
        zipCode() {
            this.saveData();
        },
        department() {
            this.saveData();
        },
        building() {
            this.saveData();
        },
        flat() {
            this.saveData();
        },
    },
    created() {
        this.countryList = this.countriesList;
        this.filterCountryList = this.countriesList;
    },
    methods: {
        ...mapActions({
            fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
            fetchDepartments: "order/NOVA_POSHTA_GET_DEPARTMENS",
            fetchCity: "zasilkovna/GET_CITY_LIST",
            fetchCountry: "zasilkovna/GET_COUNTRIES_LIST",
            fetchCarriersList: "zasilkovna/GET_CARRIERS_LIST",
            fetchBranch: "zasilkovna/GET_BRANCH_LIST",
            fetchCarrierPickupPointList: "zasilkovna/GET_CARRIER_PICKUP_POINTS_LIST",
            fetchPickupPointList: "zasilkovna/GET_PICKUP_POINTS_LIST",
        }),
        ...mapMutations({
            setDepartments: "order/NOVA_POSHTA_SET_DEPARTMENS",
            changeAddress: "order/CHANGE_ADDRESS_COURIER",
            saveAddress: "order/SAVE_DELIVERY_ADDRESS",
        }),

        getHandlerCarrier(country) {
            const payload = {
                country: country.alpha2Code,
                search: '',
                with_cod: false,
            }
            if (this.DeliveryTypePraha) {
                this.fetchCarrierPickupPointList(payload)
                    .then(res => {
                        this.carrierPickupPointList = res;
                        this.filterCarrierPickupPointList = res;
                    })
                    .catch(error => {
                        console.log('fetchCarrierPickupPointList ERROR ====> ', error);
                    })
            } else {
                this.fetchCarriersList(payload)
                    .then(res => {
                        this.carrierList = res;
                        this.filterCarrierList = res;
                    })
                    .catch(error => {
                        console.log('fetchCarriersList ERROR ====> ', error);
                    })
            }
        },
        saveData() {
            this.changeAddress(this.selectedCountry);
            this.saveAddress({
                city: this.city,
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryRef: this.selectedCountryRef,
                department: this.department,
                house: this.building,
                flat: this.flat,
                street: this.street,
                zip: this.zipCode
            })
            this.changeAddress({
                town: this.city,
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryRef: this.selectedCountryRef,
                department: this.department,
                building: this.building,
                flat: this.flat,
                street: this.street,
                zip: this.zipCode
            });
        },
        getPickupPointList(carrierId) {
            this.fetchPickupPointList({ carrierId })
                .then(res => {
                    this.pickupPointList = res;
                    this.filterPickupPointList = res.map((element) => {
                        return {
                            ...element,
                            name: `${element.city} ${element.street} ${element.streetNumber}`
                        }
                    });
                })
                .catch(error => {
                    console.log('ERROR fetchPickupPointList ==> ', error);
                })
        },
        // handleCityInput(e) {
        //     if (e.length < 3) {
        //         this.filterCityList = this.cityList.slice();
        //         return;
        //     }
        //     this.filterCityList = this.cityList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        // },
        handleCountryInput(e) {
            if(e.length < 3) {
                this.filterCountryList = this.countryList.slice();
                return;
            }
            this.filterCountryList = this.countryList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));

            // this.getHandlerCarrier();
        },
        handleCarrierInput(e) {
            if(e.length < 3) {
                this.filterCarrierList = this.carrierList.slice();
                return;
            }
            this.filterCarrierList = this.carrierList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },
        handleCarrierPickupPointInput(e) {
            if(e.length < 3) {
                this.filterCarrierPickupPointList = this.carrierPickupPointList.slice();
                return;
            }
            this.filterCarrierPickupPointList =
                this.carrierPickupPointList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },
        handlePickupPointInput(e) {
            if(e.length < 3) {
                this.filterPickupPointList = this.pickupPointList.slice();
                return;
            }
            this.filterPickupPointList =
                this.pickupPointList.filter(item => item.city.toLowerCase().includes(e.toLowerCase()));
            // console.log('filterCarrierPickupPointList ===>> ', this.filterCarrierPickupPointList)
        },
        // handleCitySelect(e) {
        //     this.selectedCityRef = e.id;
        //     this.selectedCity = e.name;
        //     this.fetchBranch({ city: this.selectedCity });
        //     this.$emit('select-city', e);
        //     this.selectedBranch = '';
        // },
        handleCountrySelect(e) {
            this.selectedCountryRef = e.alpha2Code;
            this.selectedCountry = e.name;
            // this.fetchBranch({ country: this.selectedCountry });
            // console.log('handleCountrySelect ===> ', e)
            this.$emit('select-country', {
                country: e.alpha2Code,
            })
            this.selectedBranch = '';
            this.getHandlerCarrier(e);

            this.selectedCarrierRef = null;
            this.selectedCarrier = null;
            this.selectedPickupPointCarrier = null;
            this.selectedPickupPointCarrierRef = null;
        },
        handleCarrierSelect(e) {
            this.selectedCarrierRef = e.id;
            this.selectedCarrier = e.name;
            // this.fetchBranch({ country: this.selectedCountry });
            // console.log('handleCarrierSelect ===> ', e)
            this.$emit('select-carrier', e);
            this.selectedBranch = '';
        },

        handleCarrierPickupPointSelect(e) {
            this.selectedPickupPointCarrierRef = e.id;
            this.selectedPickupPointCarrier = e.name;
            // this.fetchBranch({ country: this.selectedCountry });
            this.getPickupPointList(e.id)
            // console.log('handleCarrierPickupPointSelect ===> ', e)
            this.$emit('select-carrier-pickup-points', e);
            this.selectedBranch = '';
        },
        handlePickupPointSelect(e) {
            this.selectedPickupPointRef = e.code;
            this.selectedPickupPoint = e.name;
            // this.fetchBranch({ country: this.selectedCountry });
            // console.log('handlePickupPointSelect ===> ', e)
            this.$emit('select-carrier-pickup-points', e);
            this.selectedBranch = '';
        },
    },
};
